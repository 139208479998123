const serverDevUrl = 'http://localhost:3131'
const serverStagingUrl = 'https://deltastg.cloudxier.com'
const serverProdUrl = 'https://delta.cloudxier.com'

const clientStagingHost = 'admin-stg.cloudxier.com'
const clientProdHost = 'admin.cloudxier.com'

const server = () => {
  let url = serverDevUrl
  const host = window.location.host
  if (host == clientStagingHost) {
    url = serverStagingUrl
  }
  if (host == clientProdHost) {
    url = serverProdUrl
  }
  return url
}

module.exports = {
  server
}
