import React, {useMemo, useState, useEffect, useRef} from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './CreateBlog.css'

//components
import { Sidebar, Header, Footer, Loader } from '../components/index'
import HeadMeta from '../components/HeadMeta'

import {
  CContainer,
  CForm,
  CFormInput,
  CButton,
  CFormSelect,
} from '@coreui/react'
import {useDropzone} from 'react-dropzone'
import JoditEditor, { Jodit } from 'jodit-react';
import Select from 'react-select'

// ACTIONS
import { createBlog, getDetailBlog, editBlog } from '../redux/actions/blog_actions'
import { getAllCategory } from '../redux/actions/category_actions'

// Images
import UploadImage from '../assets/image/upload.png'
import CloudxierLogoSquare from '../assets/image/logo/Cloudxier-logofull-colored-05.png'

import Swal from 'sweetalert2';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px',
  borderWidth: 1,
  borderColor: 'black',
  borderStyle: 'solid',
  backgroundColor: '#fff',
  borderRadius: '0.375rem',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3',
  backgroundColor: '#E5E5E5',
};

const acceptStyle = {
  borderColor: '#00e676',
  backgroundColor: '#E5E5E5',
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function CreateBlog (props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.blogs);

  const [blogDetail, setBlogDetail] = useState({
    summary: '',
    title: '',
    content: '',
    imgThumbnail: '',
    imgCover: '',
    status: null,
  })

  const editor = useRef(null)
  const [content, setContent] = useState('')

  const [filesThumbnail, setFilesThumbnail] = useState([]);
  const [filesCover, setFilesCover] = useState([]);

  const [summaryValidated, setSummaryValidated] = useState(false)
  const [titleValidated, setTitleValidated] = useState(false)
  const [thumbnailValidated, setThumbnailValidated] = useState(false)
  const [coverValidated, setCoverValidated] = useState(false)
  const [contentValidated, setContentValidated] = useState(false)
  const [statusValidated, setStatusValidated] = useState(false)
  const [categoriesValidated, setCategoriesValidated] = useState(false)

  const [isValidSummary, setIsValidSummary] = useState(false)
  const [isValidTitle, setIsValidTitle] = useState(false)
  const [isValidThumbnail, setIsValidThumbnail] = useState(false)
  const [isValidCover, setIsValidCover] = useState(false)
  const [isValidContent, setIsValidContent] = useState(false)
  const [isValidStatus, setIsValidStatus] = useState(false)
  const [isValidCategories, setIsValidCategories] = useState(false)

  const [errorSummaryMessage, setErrorSummaryMessage] = useState("")
  const [errorThumbnailMessage, setErrorThumbnailMessage] = useState("")
  const [errorCoverMessage, setErrorCoverMessage] = useState("")

  const [showRemoveImgModal, setShowRemoveImgModal] = useState(false)
  const [nameButton, setNameButton] = useState("")

  const [categoriesArr, setCategoriesArr] = useState([])
  const [categorySelectedArr, setCategorySelectedArr] = useState([])

  useEffect(() => {
    const {
      computedMatch: {
        params: {
          id
        } = {}
      } = {}
    } = props || {}
    if (id) {
      dispatch(getDetailBlog(id)).then((data) => {
        if (data.success) {
          if (data.response) {
            const response = data.response
            if (response.content) {
              setContent(response.content)
            }

            if (response.title) {
              setIsValidTitle(true)
            }

            if (response.img_thumbnail) {
              setIsValidThumbnail(true)
            }

            if (response.img_cover) {
              setIsValidCover(true)
            }

            if (response.content) {
              setIsValidContent(true)
            }

            if (response.status) {
              setIsValidStatus(true)
            }

            if (response.summary) {
              setIsValidSummary(true)
            }

            if (response.blogcategories && response.blogcategories.length > 0) {
              setIsValidCategories(true)
              let categories = response.blogcategories.map((res) => {
                return {
                  value: res.category_id,
                  label: res && res.category && res.category.category_name
                }
              })

              setCategorySelectedArr(categories)
            }

            setBlogDetail({
              ...response,
              blogId: response.blog_id,
              status: response.status,
              imgThumbnail: response.img_thumbnail,
              imgCover: response.img_cover,
            })
          }
        }
      })
    }

    dispatch(getAllCategory())
      .then((data) => {
        if (data && data.success) {
          let categoryArr = data && data.response && data.response.map((res) => {
            return {
              value: res.id,
              label: res.category_name
            }
          })
          setCategoriesArr(categoryArr)
        }
      })
  }, [])

  const {
    getRootProps: getRootThumbnailProps, 
    getInputProps: getInputThumbnailProps,     
    isFocused: isThumbnailFocused,
    isDragAccept: isDragThumbnailAccept,
    isDragReject: isDragThumbnailReject
  } = useDropzone({
    accept: {'image/jpeg': [], 'image/png': [], 'image/jpg': []},
    multiple: false,
    maxSize: 1000000,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections && fileRejections.length > 0) {
        setThumbnailValidated(true)
        setIsValidThumbnail(false)
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorThumbnailMessage(err.message);
            }
  
            if (err.code === "file-invalid-type") {
              setErrorThumbnailMessage(err.message);
            }
          });
        });
      } else {
        setFilesThumbnail(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
        if (acceptedFiles && acceptedFiles.length > 0) {
          setIsValidThumbnail(true)
          setBlogDetail({
            ...blogDetail,
            imgThumbnail: ''
          })
        }
      }
    }
  });

  const {
    getRootProps: getRootCoverProps, 
    getInputProps: getInputCoverProps,     
    isFocused: isCoverFocused,
    isDragAccept: isDragCoverAccept,
    isDragReject: isDragCoverReject
  } = useDropzone({
    accept: {'image/jpeg': [], 'image/png': [], 'image/jpg': []},
    multiple: false,
    maxSize: 1000000,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections && fileRejections.length > 0) {
        setCoverValidated(true)
        setIsValidCover(false)
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorCoverMessage(err.message);
            }
  
            if (err.code === "file-invalid-type") {
              setErrorCoverMessage(err.message);
            }
          });
        });
      } else {
        setFilesCover(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
        if (acceptedFiles && acceptedFiles.length > 0) {
          setIsValidCover(true)
          setBlogDetail({
            ...blogDetail,
            imgCover: ''
          })
        }
      }
    }
  });

  const styleDropZoneThumbnail = useMemo(() => ({
    ...baseStyle,
    ...(isThumbnailFocused ? focusedStyle : {}),
    ...(isDragThumbnailAccept ? acceptStyle : {}),
    ...(isDragThumbnailReject || (isValidThumbnail === false && thumbnailValidated) ? rejectStyle : {})
  }), [
    isThumbnailFocused,
    isDragThumbnailAccept,
    isDragThumbnailReject,
    isValidThumbnail,
    thumbnailValidated
  ]);

  const styleDropZoneCover = useMemo(() => ({
    ...baseStyle,
    ...(isCoverFocused ? focusedStyle : {}),
    ...(isDragCoverAccept ? acceptStyle : {}),
    ...(isDragCoverReject || (isValidCover === false && coverValidated) ? rejectStyle : {})
  }), [
    isCoverFocused,
    isDragCoverAccept,
    isDragCoverReject,
    isValidCover,
    coverValidated
  ]);

  const changeInputBlogHandler = (e) => {
    const value = e.target.value
    const name = e.target.name

    if (name === 'summary') {
      setSummaryValidated(true)
      if (value === '' && value.length < 1) {
        setIsValidSummary(false)
        setErrorSummaryMessage("Please fill this out field")
      } else {
        if (value.length < 225) {
          setIsValidSummary(false)
          setErrorSummaryMessage("Summary must be at least 225 characters")
        } else {
          setIsValidSummary(true)
          setErrorSummaryMessage("")
        }
      }
    }

    if (name === 'title') {
      setTitleValidated(true)
      if (value === '' && value.length < 1) {
        setIsValidTitle(false)
      } else {
        setIsValidTitle(true)
      }
    }

    if (name === 'status') {
      setStatusValidated(true)
      if (value === null || value === 'Select') {
        setIsValidStatus(false)
      } else {
        setIsValidStatus(true)
      }
    }
    setBlogDetail({
      ...blogDetail,
      [name]: value,
    })
  }

  const handleValidationSubmit = (e) => {
    const {
      computedMatch: {
        params: {
          id
        } = {}
      } = {}
    } = props || {}

    const form = e.currentTarget

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation()
    }

    setSummaryValidated(true)
    setTitleValidated(true)
    setStatusValidated(true)
    setContentValidated(true)
    setThumbnailValidated(true)
    setCoverValidated(true)
    setCategoriesValidated(true)

    const payload = {
      ...blogDetail,
      filesThumbnail,
      filesCover,
      categories: []
    }

    if (categorySelectedArr && categorySelectedArr.length <= 0) {
      setIsValidCategories(false)
    } else {
      setIsValidCategories(true)
      categorySelectedArr.forEach((res) => {
        const {
          value,
        } = res || {};
        payload.categories.push(`${value}`)
      })
    }

    if (id) {
      payload.id = id
      if (isValidSummary && 
        isValidTitle && 
        isValidThumbnail && 
        isValidCover &&
        isValidContent && 
        isValidStatus && 
        isValidCategories && 
        blogDetail.blogId !== '' &&
        blogDetail.content !== '' &&
        blogDetail.status !== null &&
        blogDetail.title !== '' && 
        categorySelectedArr.length > 0) {
        dispatch(editBlog(payload))
        .then((response) => {
          if (response.success) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Blog edited successfully!',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              history.push('/')
            })
          }
        })
        .catch(err => {
          let errMessage = err && err.message
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        })
      }
    } else {
      if (isValidSummary && 
          isValidTitle && 
          isValidThumbnail && 
          isValidCover &&
          isValidContent && 
          isValidStatus &&
          isValidCategories && 
          blogDetail.blogId !== '' &&
          blogDetail.content !== '' &&
          blogDetail.status !== null &&
          blogDetail.title !== '' &&
          categorySelectedArr.length > 0) {
        dispatch(createBlog(payload))
          .then((response) => {
            if (response.success) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Blog created successfully!',
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                history.push('/')
              })
            }
          })
          .catch(err => {
            let errMessage = err && err.message
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: errMessage,
            });
          })
      } else {
        if (blogDetail && blogDetail.summary === '' && blogDetail.summary.length === 0) {
          setIsValidSummary(false)
          setErrorSummaryMessage("Please fill this out field")
        } else {
          setIsValidSummary(true)
          setErrorSummaryMessage("")
        }

        if (blogDetail && blogDetail.content === '' && blogDetail.content.length === 0) {
          setIsValidContent(false)
        } else {
          setIsValidContent(true)
        }
  
        if (filesThumbnail && Array.isArray(filesThumbnail) && filesThumbnail.length === 0) {
          setIsValidThumbnail(false)
        } else {
          setIsValidThumbnail(true)
        }

        if (filesCover && Array.isArray(filesCover) && filesCover.length === 0) {
          setIsValidCover(false)
        } else {
          setIsValidCover(true)
        }
      }
    }
  }

  const handleSubmitBlog = (e) => {
    e.preventDefault();
    handleValidationSubmit(e)
  }

  useEffect(() => {
    if (showRemoveImgModal) {
      Swal.fire({
        title: 'Are you sure to remove this image?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#336AFE',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Remove'
      }).then((result) => {
        if (result.isConfirmed) {
          setBlogDetail({
            ...blogDetail,
            [`${nameButton}`]: ''
          })
          setNameButton("")
          setShowRemoveImgModal(false)
        }
      })
    }
  }, [showRemoveImgModal])

  const handleShowRemoveImgModal = (e) => {
    const name = e.target.name
    setNameButton(name)
    setShowRemoveImgModal(true)
  }

  const onBlurEditorChange = () => {
    setContent(editor.current.value)
  }

  useEffect(() => {
    setIsValidContent(isValidContent)
  }, [isValidContent])

  const onEditorChange = (newContent) => {
    const plainText = Jodit.modules.Helpers.stripTags(newContent)
    if (plainText.length > 0) {
      setContentValidated(true)
      setIsValidContent(true)
    } else {
      setContentValidated(true)
      setIsValidContent(false)
    }

    setBlogDetail({
      ...blogDetail,
      ['content']: newContent
    })
  }

  let editorStyle = {
    border: !isValidContent && contentValidated ? '1px solid red' : '1px solid #000000',
    borderRadius: '0.375rem'
  }

  let config = useMemo(
    () => ({
      readonly: false,
      uploader: { insertImageAsBase64URI: true },
      toolbarAdaptive: false,
      "showXPathInStatusbar": false,
      "showCharsCounter": false,
      "showWordsCounter": false,
      "useSearch": true,
      "askBeforePasteHTML": false,
      "cleanHTML": {
        "fillEmptyParagraph": false
      },
      "disablePlugins": "xpath,about,font, print",
      style: { fontSize: "18px", color: "black" },
      "defaultActionOnPaste": 'insert_as_html',
      "defaultActionOnPasteFromWord": 'insert_as_html',
      "processPasteFromWord": true,
      "processPasteHTML": true,
      events: {
        paste: (e) => {
          let newContent = e.clipboardData.getData('text/html')
          newContent = newContent.replace(/font-size[^;]+/g,"font-size: 18px"); // remove font-size...
          newContent = newContent.replace(/font-family[^;]+/g,""); // remove font-family...
          editor.current.value = newContent; // ref
          onBlurEditorChange()
          return e;
        }
      }
    }),
    []
  );

  const handleDropdownChange = (v, metaValue) => {
    if (metaValue && metaValue.option) {
      let selectedValue = metaValue.option
      setCategorySelectedArr(selected => [...selected, selectedValue])
    } else if (metaValue && metaValue.removedValue) {
      let removeSelection = categorySelectedArr
      removeSelection = removeSelection.filter((res) => res.value !== metaValue.removedValue.value)

      setCategorySelectedArr(removeSelection)
    }
  }

  return (
    <div>
      <HeadMeta
        title={props && props.computedMatch && props.computedMatch.params && props.computedMatch.params.id ? `Edit Blog` : 'Create Blog'}
        description={props && props.computedMatch && props.computedMatch.params && props.computedMatch.params.id ? `Edit Blog` : 'Create Blog'}
        siteName="Cloudxier"
        url={window.location.href}
        image={CloudxierLogoSquare}
      />
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-white">
        <Header props={props} />
        <div className="body flex-grow-1 px-3">
          <CContainer lg className="p-5">
            <div>
              <CForm onSubmit={handleSubmitBlog} noValidate>
                <div className="d-flex justify-content-between mb-4">
                  <div>
                    {
                      props && props.computedMatch && props.computedMatch.params && props.computedMatch.params.id ? (
                        <h3>Edit Blog</h3>
                      )
                      : (
                        <h3>Add New Blog</h3>
                      )
                    }
                  </div>
                  <div className='d-flex flex-column'>
                    <div>
                      <h5>Status</h5>
                    </div>
                    <div>
                    <CFormSelect 
                      aria-label="Default select example"
                      options={[
                        { label: 'Select', value: null },
                        { label: 'Enabled', value: 'Enabled' },
                        { label: 'Hidden', value: 'Hidden' }
                      ]}
                      name='status'
                      value={blogDetail.status}
                      onChange={changeInputBlogHandler}
                      feedbackInvalid="Please choose valid status"
                      feedbackValid="Looks good!"
                      valid={isValidStatus && statusValidated}
                      invalid={!isValidStatus && statusValidated}
                      required
                    />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div>
                    <span>Title</span>
                    <span className='text-danger'>*</span>
                  </div>
                  <CFormInput 
                    className="form-input" 
                    type='text'
                    name="title" 
                    value={blogDetail.title} 
                    onChange={changeInputBlogHandler} 
                    aria-describedby="validationTitle" 
                    id="validationTitle" 
                    feedbackInvalid="Please fill this out field" 
                    feedbackValid="Looks good!"
                    valid={isValidTitle && titleValidated}
                    invalid={!isValidTitle && titleValidated}
                    required/>
                </div>
                <div className="mb-3">
                  <div>
                    <span>Post Summary</span>
                    <span className='text-danger'>*</span>
                  </div>
                  <CFormInput 
                    className='form-input' 
                    type='text' 
                    name="summary" 
                    value={blogDetail.summary} 
                    onChange={changeInputBlogHandler}  
                    aria-describedby="validationSummary"  
                    id="validationSummary" 
                    feedbackInvalid={errorSummaryMessage}
                    feedbackValid="Looks good!"
                    valid={isValidSummary && summaryValidated}
                    invalid={!isValidSummary && summaryValidated}
                    required/>
                </div>
                <div className="mb-3">
                  <div>
                    <span>Post Thumbnail</span>
                    <span className='text-danger'>*</span>
                  </div>
                  <div {...getRootThumbnailProps({style: styleDropZoneThumbnail, className: "dropzone"})}>
                    <input {...getInputThumbnailProps()} />
                    <div className='mt-4'>
                      <img className='preview-img' src={UploadImage} style={{width: '40px'}}/>
                    </div>
                    <div>
                      <span className='invalid-text'>Drop your image here, or browse</span>
                    </div>
                    <div>
                      <span className='invalid-text'>Recommended image size is 1 MB</span>
                    </div>
                  </div>
                  {
                    isValidThumbnail === false && thumbnailValidated && filesThumbnail.length === 0 && (
                      <div>
                        <span className="text-danger fw-normal invalid-text">Please fill this out field</span>
                      </div>
                    )
                  }
                    {
                    isValidCover === false && thumbnailValidated && errorThumbnailMessage && (
                      <div>
                        <span className="text-danger fw-normal invalid-text">{errorThumbnailMessage}</span>
                      </div>
                    )
                  }
                  {
                    filesThumbnail && Array.isArray(filesThumbnail) && filesThumbnail.length > 0 && filesThumbnail.map((file) => (
                      <div className="preview-container">
                        <div className="preview-body" key={file.name}>
                          <div className="preview-inner">
                            <img
                              className='preview-img'
                              src={file.preview}
                              // Revoke data uri after image is loaded
                              onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  {
                    blogDetail && blogDetail.imgThumbnail !== '' && (
                      <div className="preview-container">
                        <div className="preview-body">
                          <div className='d-flex flex-column'>
                            <div className="preview-inner">
                              <img
                                className='preview-img'
                                src={blogDetail.imgThumbnail}
                                // Revoke data uri after image is loaded
                                onLoad={() => { URL.revokeObjectURL(blogDetail.imgThumbnail) }}
                              />
                            </div>
                            <div>
                            <CButton className='mt-2 px-4' color="danger" variant='outline' shape="rounded-pill" name='imgThumbnail' onClick={(e) => handleShowRemoveImgModal(e)}>Remove Image</CButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
                <div className="mb-3">
                  <div>
                    <span>Post Cover</span>
                    <span className='text-danger'>*</span>
                  </div>
                  <div {...getRootCoverProps({style: styleDropZoneCover, className: "dropzone"})}>
                    <input {...getInputCoverProps()} />
                    <div className='mt-4'>
                      <img className='preview-img' src={UploadImage} style={{width: '40px'}}/>
                    </div>
                    <div>
                      <span className='invalid-text'>Drop your image here, or browse</span>
                    </div>
                    <div>
                      <span className='invalid-text'>Recommended image size is 1 MB</span>
                    </div>
                  </div>
                  {
                    isValidCover === false && coverValidated && !errorCoverMessage && (
                      <div>
                        <span className="text-danger fw-normal invalid-text">Please fill this out field</span>
                      </div>
                    )
                  }
                  {
                    isValidCover === false && coverValidated && errorCoverMessage && (
                      <div>
                        <span className="text-danger fw-normal invalid-text">{errorCoverMessage}</span>
                      </div>
                    )
                  }
                  {
                    filesCover && Array.isArray(filesCover) && filesCover.length > 0 && filesCover.map((file) => (
                      <div className="preview-container">
                        <div className="preview-body" key={file.name}>
                          <div className="preview-inner">
                            <img
                              className='preview-img'
                              src={file.preview}
                              // Revoke data uri after image is loaded
                              onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  {
                    blogDetail && blogDetail.imgCover !== '' && (
                      <div className="preview-container">
                        <div className="preview-body">
                          <div className='d-flex flex-column'>
                            <div className="preview-inner">
                              <img
                                className='preview-img'
                                src={blogDetail.imgCover}
                                // Revoke data uri after image is loaded
                                onLoad={() => { URL.revokeObjectURL(blogDetail.imgCover) }}
                              />
                            </div>
                            <div>
                            <CButton className='mt-2 px-4' color="danger" variant='outline' shape="rounded-pill" name='imgCover' onClick={(e) => handleShowRemoveImgModal(e)}>Remove Image</CButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
                <div className="mb-3">
                  <div>
                      <span>Content Blog</span>
                      <span className='text-danger'>*</span>
                  </div>
                    <div className='editor' style={editorStyle}>
                      <JoditEditor 
                        ref={editor}
                        value={content}
                        onChange={newContent => onEditorChange(newContent)}
                        onBlur={onBlurEditorChange}
                        config={config}
                      />
                    </div>
                    {
                      isValidContent === false && contentValidated && (
                        <div>
                          <span className="text-danger fw-normal invalid-text">Please fill this out field</span>
                        </div>
                      )
                    }
                </div>
                <div className="mb-3">
                  <div>
                    <span>Category</span>
                    <span className='text-danger'>*</span>
                  </div>
                  <div>
                    <Select 
                      className={`form-input ${isValidCategories === false && categoriesValidated ? 'invalid' : ''}`}
                      isMulti
                      value={categorySelectedArr}
                      options={categoriesArr}
                      onChange={(value, e) => handleDropdownChange(value, e)}
                      required
                      isClearable
                    />
                  </div>
                  {
                    isValidCategories === false && categoriesValidated && (
                      <div>
                       <span className="text-danger fw-normal invalid-text">Please fill this out field</span>
                      </div>
                    )
                  }
                </div>
                <div className="d-flex justify-content-end">
                  <CButton className='me-2 px-4' color="dark" onClick={() => history.push('/')} variant='outline' shape="rounded-pill">Cancel</CButton>
                  <CButton className='px-4' color="dark" type='submit' variant='outline' shape="rounded-pill">Submit</CButton>
                </div>
              </CForm>
            </div>
          </CContainer>
        </div>
        <Loader show={loading} />
        <Footer />
      </div>
    </div>
  )
}

export default CreateBlog